import { RestaurantsOrderPacingPacer } from '@wix/ambassador-restaurants-order-pacing-pacer/http';
import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import { convertOrderPacingDelayToLevel } from '@wix/restaurants-client-logic';

export async function fetchOrderPacingCurrentLevel(
  signedInstance: string,
  locationId: string | undefined,
  fedopsLogger: IFedOpsLogger,
): Promise<number> {
  const orderPacingPacer = RestaurantsOrderPacingPacer('/_api/order-pacing-pacer/').OrderPacingService()({
    Authorization: signedInstance,
  });

  fedopsLogger && fedopsLogger.interactionStarted('get-order-pacing-level');
  try {
    const currentDelayResponse = await orderPacingPacer.getCurrentDelay({ locationId });
    const currentDelay = currentDelayResponse?.delay?.durationInMinutes;
    const currentLevel = convertOrderPacingDelayToLevel(currentDelay);

    fedopsLogger && fedopsLogger.interactionEnded('get-order-pacing-level');
    return currentLevel;
  } catch (e) {
    console.error('unable to get the current order pace:', e);
    return 0;
  }
}
